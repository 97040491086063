@import 'workflow';
@import 'imagemap';

// * {
//     margin: 0;
//     padding: 0;
// }

html, body, #root {
    min-height: 100%;
    width: 100%;
    // overflow: hidden;
}

video {
    left: 0;
    top: 0;
}

.rde-main {
    // overflow: hidden;
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    .rde-title {
        position: absolute;
        width: 100%;
        height: 46px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        z-index: 5;
        .menu {
            // background: #fff;
            overflow: hidden;
            // box-shadow: 0 0 0 0;
            // border-bottom: 0;
            // -webkit-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
            // -moz-box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
            // box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.5);
        }
    }
    .rde-content {
        background: rgb(247, 247, 247);
        margin-top: 46px;
        height: 100%;
        .ant-layout {
            background: none;
        }
    }
    .rde-preview {
        position: absolute;
        transform: scale(0);
        transition: transform 0.3s linear;
        transform-origin: bottom right;
        &.preview {
            width: 100%;
            height: 100%;
            transform: scale(1);
            background: #fff;
            z-index: 5000;
        }
        &-close-btn {
            position: absolute;
            top: 12px;
            right: 12px;
        }
    }
}

.rde-content-layout {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #F2F4F8;
    &-title {
        width: 100%;
        height: 38px;
        background: rgba(217, 217, 217, 0.45);
        border-bottom: 1px solid #f3f3f3;
        line-height: 38px;
        &-title {
            justify-content: flex-start;
            margin-left: 30px;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.65);
            font-weight: 500;
            div {
                line-height:35px;
            }
        }
        &-content {
            margin-left: 60px;
        }
        &-action {
            margin-right: 12px;
        }
    }
    &-main {
        padding: 20px;
    }
    &-sider {
        background: #f3f3f3;
        border-right: 1px solid #f3f3f3;
        .ant-tree {
            margin: 5px 0;
            li {
                padding: 0;
            }
            .ant-tag {
                width: 18px;
                height: 18px;
                line-height: 16px;
                font-size: 8px;
                padding: 0 4px;
                opacity: 0.8;
            }
            .ant-badge {
                opacity: 0.8;
                margin-left: 8px;
            }
        }
    }
    .ant-layout {
        background: #F2F4F8;
    }
}

.ant-btn[disabled] {
    background: transparent;
    border-color: transparent;
}

.ant-btn {
    &.rde-action-button {
        background: transparent;
        border-color: transparent;
        &.ant-btn-danger {
            color: #f04134;
            &:focus {
                color: #f04134;
                background: unset;
                border-color: transparent;
            }
            &:hover {
                color: #fff;
                background-color: #f46e65;
                border-color: #f46e65;
            }
        }
        &:focus {
            color: unset;
            background: unset;
            border-color: transparent;
        }
        &:hover {
            background: rgba(219, 234, 251, 0.85);
            border-color: transparent;
        }
    }
}

.rde-action-btn {
    background: transparent;
    border: transparent;
    button {
        background: transparent !important;
        border-color: transparent !important;
        border: unset;
    }
    &:hover {
        background: transparent;
        border: transparent;
    }
    &:focus {
        background: transparent;
        border: transparent;
    }
}

.rde-icon-container {
    border-radius: 4px;
    margin: 8px;
    text-align: center;
    cursor: pointer;
    .rde-icon-top {
        transition: .5s ease;
        color: inherit;
        background-color: transparent;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        i {
            margin: 8px 0 8px 0;
        }
        &:hover, &:focus {
            background-color: #4dabf7;
            color: #fff;
        }
    }
    .rde-icon-bottom {
        transition: .5s ease;
        color: #adb5bd;
        font-size: .8rem;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        &:hover, &foucs {
            background-color: rgba(0, 0, 0, .2);
            color: #495057;
        }
    }
}

.rde-action-tag {
    width: 32px;
    padding: 0;
    font-size: 16px;
    border-radius: 50%;
    height: 32px;
    text-align: center;
    padding-top: 4px;
}

.rde-editable-cell {
    padding: 5px 12px;
    &:hover {
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        padding: 4px 11px;
    }
    cursor: pointer;
}

[draggable] {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -khtml-user-drag: element;
    -webkit-user-drag: element;
    cursor: pointer;
}

.rde-track-vertical {
    right: 0px;
    bottom: 2px;
    top: 2px;
    border-radius: 3px;
    opacity: 0;
}

.saved {
    opacity: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    border-radius: 3px;
    
    &-visible {
        opacity: .7;
    }
}

.autosave-notif {
    color: grey;
    color: rgba(139, 143, 145, 0.5);
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
    text-align: right;
}
.autosave-notif .ant-notification-notice-message {
    color: grey;
    color: rgba(139, 143, 145, 0.5);
    padding: 0;    
}
.autosave-notif .ant-notification-notice-close {
    display: none;
}

.scrollable-container {
    position: relative;
    height: calc(100vh - 60px);
    overflow: auto;
}