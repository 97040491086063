.cropper-app {
    height: 100%;
    width: 100%;
}

.cropper-container {
    width: 100%;
    height: 400px;
    position: relative;
}

.cropper-slider {
    padding: 22px 0px;
}
  