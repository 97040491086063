.rde-editor {
    &-canvas-container {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
    &-header-toolbar {
        background: rgba(0, 0, 0, 0.05);
        transition: width .05s linear;
        display: flex;
        height: 40px;
        z-index: 800;
        -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        &-container {
            .rde-canvas-toolbar {
                display: flex;
                align-items: center;
                position: relative;
                margin-left: 4px;
                margin-right: 4px;
                &:not(:last-child):after {
                    background-color: #fff;
                    position: absolute;
                    right: -5px;
                    width: 1px;
                    height: 20px;
                    content: "";
                }
                &-list {
                    &:hover {
                        .rde-canvas-list {
                            background: rgba(0, 0, 0, 0.05);
                            display: block;
                            z-index: 1;
                            -webkit-animation: slide-right .3s linear;
                            -moz-animation: slide-right .3s linear;
                            animation: slide-right .3s linear;
                        }
                    }
                }
                &-history {
                    flex: 1;
                    justify-content: flex-end;
                }
                &-action {
                    flex: 1;
                    justify-content: flex-end;
                }
                .rde-canvas-list {
                    position: absolute;
                    display: none;
                    width: 300px;
                    height: -webkit-calc(100vh - 140px);
                    height:    -moz-calc(100vh - 140px);
                    height:         calc(100vh - 140px);
                    top: 40px;
                    left: -4px;
                    z-index: 0;
                    -webkit-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
                    -moz-box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
                    box-shadow: 5px 0 5px -5px rgba(0, 0, 0, 0.5);
                    &-actions {
                        border-bottom: 1px solid;
                    }
                    &-items {
                        overflow-y: auto;
                    }
                    &-item {
                        position: relative;
                        margin: 8px 8px;
                        text-align: center;
                        &-icon {
                            margin: 0 8px 0 4px;
                        }
                        &-text {
                            font-size: 16px;
                        }
                        &-actions {
                            margin: 0 4px 0 8px;
                        }
                        &.selected-item {
                            background: rgba(0, 0, 0, .2);
                            box-shadow: 0 0 11px rgba(33, 33, 33, .2);     
                        }
                        &:hover {
                            cursor: pointer;
                            background: rgba(0, 0, 0, .2);
                            box-shadow: 0 0 11px rgba(33, 33, 33, .2); 
                        }
                        &::after {
                            position: absolute;
                            left: 0;
                            bottom: -4px;;
                            background: #fff;
                            width: 100%;
                            height: 1px;
                            content: '',
                        }
                    }
                }
            }
        }
    }
    &-footer-toolbar {
        position: relative;
        &-interaction {
            color: #fff;
            background: rgba(0, 0, 0, 0.25);
            position: absolute;
            display: block;
            bottom: 30px;
            left: 40px;
            border-radius: 12px;
            -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        }
        &-zoom {
            background: rgba(0, 0, 0, 0.25);
            position: absolute;
            display: block;
            bottom: 30px;
            left: 150px;
            border-radius: 12px;
            -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        }
        &-preview {
            background: rgba(0, 0, 0, 0.25);
            position: absolute;
            display: block;
            bottom: 35px;
            right: 30px;
            border-radius: 12px;
            -webkit-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            -moz-box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
            box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.5);
        }
    }
}