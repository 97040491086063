$primary-color:#ac3454;
$text-color: #494949;

body {
  /* text-align: center; */
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-family: Georgia, 'Times New Roman', Times, serif;
}

h1,h2,h3,h4 {
  width: 100%;
}

p {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.primaryColor {
  color: $primary-color;
}

body.light-mode {
  background: #fff;
  color: $text-color;
}

.reader-page, .writer-box {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

body.dark-mode {
  @mixin text-color() {
    color: #dfdfdf;
  }

  @mixin x-light-dark() {
    background: #678cb4;
    @include text-color();
    border: 0;
    .light-text {
      @include text-color();
    }
  }

  @mixin light-dark() {
    background: #476586;
    @include text-color();
    border: 0;
    .light-text {
      @include text-color();
    }
  }

  @mixin medium-dark-bordered() {
    background: #1b2938;
    @include text-color();
    .light-text {
      @include text-color();
    }
  }

  @mixin medium-dark() {
    @include medium-dark-bordered();
    border: 0;
  }

  @mixin heavy-dark() {
    background-color: #10171d;
    @include text-color();
    border: 0;
    .light-text {
      @include text-color();
    }
  }

  @include heavy-dark();

  .rde-editor-items-item:not(:last-child):after {
    background-color: transparent;
  }

  h1, h2, h3, h4, h5, label {
    @include text-color();
  }

  .text-darkable {
    @include text-color();
  }

  .divider-darkable {
    border-top-color: #dfdfdf;
  }

  .primaryColorDarkable {
    color: $primary-color
  }

  .apple-signin, .apple-signin:hover {
    color: black;
    background-color: white;
    border-color: transparent;
  }

  // heavy dark
  .heavy-darkable {
    @include heavy-dark();

    .reader-page {
      color: #333
    }

    // .ant-statistic-title {
    //   @include text-color();
    // }
  }

  // medium dark
  .medium-darkable {
    @include medium-dark();

    .includeDarkMode {
      .ant-tabs-tab, .ant-tabs-new-tab {
        @include light-dark();
      }

      .ant-tabs-tab-active {
        @include x-light-dark();
      }
    }

    .ant-collapse-content {
      @include light-dark();
    }

    .ant-card-actions, .ant-select-selector, .ant-popover, .ant-card-meta-title {
      @include medium-dark();
    }

    .has-error .ant-input {
      @include medium-dark();
    }

    .ant-popover-content {
      color:black;
    }

    .sider-menu {
      border-right: none;
    }

    .sider-menu .ant-menu-item-selected {
      background-color: rgba(255, 255, 255, 0.164);
    }
  }

  .medium-darkable-button {
    @include medium-dark();
    &:hover,
    &:focus {
        @include light-dark();
    }
  }

  .medium-darkable-bordered {
    @include medium-dark-bordered();
  }

  .medium-darkable-modal {
    .ant-modal-content, .ant-modal-header {
      @include medium-dark();
    }
    .ant-modal-title, .ant-modal-close-x {
      @include text-color();
    }
    .ant-select-selector, .ant-select-dropdown-menu-item {
      @include light-dark();
    }
    input, li {
      @include light-dark()
    }
  }

  // light dark
  .light-darkable {
    @include light-dark();

    .ant-select-selector {
      @include light-dark();
    }
  }

  .ignore-darkable {
    color: black !important;
  }
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: rgb(122, 122, 122);
  font-style: italic;
}

blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

.ant-timeline-item-head {
  background-color: transparent;
}

.react-joyride__tooltip button { // add this class and publicly document
  background-color: #334150 !important;
  border-radius: 4px !important;
  &:hover,
  &:focus {
       background-color: #6f90b3 !important; //important would be required since styles are added inline, this would be required with or without the custom component.
  }
}

.ant-card-actions {
  background: none;
  border: none;
}

.ant-menu {
  background: none;
}

.ant-btn-primary {
  background-color: $primary-color;
  border: none;
}
.ant-btn-primary:hover {
  background-color: rgb(172, 52, 84, 0.8);
}

.ant-menu-item .anticon {
  margin: 0;
}

.ant-layout-sider {
  background: #fff;
  background-color: #fff;
}

.apple-signin, .apple-signin:hover {
  color: white;
  background-color: black;
  border-color: transparent;
}

.clickable {
  cursor: pointer;
}

.hide {
  display: none;
}

.hoverable:hover {
  box-shadow: inset 0 0 0 100em rgba(255, 255, 255, 0.3);
}

.no-padins {
  margin: 0;
  padding: 0;
}

.not-selectable {
  -moz-user-select: none;  
  -webkit-user-select: none;  
  -ms-user-select: none;  
  -o-user-select: none;  
  user-select: none;
}

.custom_font_select {
  p {
    margin: 0;
  }
}

.editable-image-wrapper {
  position: relative;
  text-align: center;
  color: white;
  cursor: pointer;
}

.editable-image-wrapper .editable-image-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility:hidden;
}
.editable-image-wrapper .editable-image-text svg{
  margin: 5px;
}

.editable-image {
  -webkit-filter: brightness(100%);
}

.editable-image-wrapper:hover .editable-image{
  -webkit-filter: brightness(50%);
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  -ms-transition: all 1s ease;
  transition: all 1s ease;
}

.editable-image-wrapper:hover .editable-image-text {
  visibility:visible;
  z-index: 1;
}

.fable-button {
  border: 1px solid $primary-color;
  color: $primary-color;
}
.fable-button-inverse {
  background: $primary-color;
  color: #fff;
}

.secondary {
  color: #808080;
}

.line-break {
  white-space: pre-line;
}

.white-background {
  background: #fff;
}

.center-form {
  width: 50%;
  max-width: 1920px;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 5%;
  overflow: initial;
  margin: auto;
  margin-top: 10vh;
}

.ant-form-item {
  padding-bottom: 10px;
}

.story-card-description-text, .dropdown-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.center-text {
  text-align: center;
}

.nav-text {
  margin-left: 10px;
}

.ant-layout-sider-collapsed .nav-text, 
.collapsed,
.ant-layout-sider-collapsed .ant-menu-submenu-vertical > .ant-menu-submenu-title:after
{
  display: none;
}

.ant-tabs {
  .ant-tabs-bar {
      margin: 0;
  }
  .ant-tabs-content {
      // height: -webkit-calc(100% - 44px);
      // height:    -moz-calc(100% - 44px);
      // height:         calc(100% - 44px);
      .ant-tabs-tabpane {
          overflow-y: auto;
      }
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  max-height: 100%;
}

/* Container holding the image and the text */
.cover-container {
  position: relative;
  text-align: center;
  color: white;
}

.cover-text-top-left {
  position: absolute;
  top: 8px;
  left: 16px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #fff;
  background-color:#000;
}

.cover-text-top-right {
  position: absolute;
  top: 8px;
  right: 16px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #fff;
  background-color:#000;
}

.cover-text-bottom-left {
  position: absolute;
  bottom: 8px;
  left: 16px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #fff;
  background-color:#000;
}

.cover-text-bottom-right {
  position: absolute;
  bottom: 8px;
  right: 16px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #fff;
  background-color:#000;
  text-align: right;
}

.cover-text-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  color: #fff;
  background-color:#000;
}

.iframe-container {
  overflow: hidden;
  // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
  padding-top: 56.25%;
  position: relative;
}
 
.iframe-container iframe {
   border: 0;
   height: 100%;
   left: 0;
   position: absolute;
   top: 0;
   width: 100%;
}

.hide-scroll {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar {
    width: 0px !important;
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
}

.show-scroll {
  scrollbar-width: thin; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: none !important;
    background-color: transparent !important;
  }
  
  ::-webkit-scrollbar {
    width: 6px !important;
    background-color: transparent !important;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 5px;
  }
}

@media (max-width: 767px) {
  .mobile-stretch {
    min-width: 100%;
    left: 0;
    padding: 10px;
  }

  .center-form {
    width: 100%;
  }

  .reader {
    width: 100%;
  }
}