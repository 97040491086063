@import "./_custom.scss";

.teams0-wrapper {
  height: 50vh;
  // background: rgba(255, 255, 255, 0);
  background-image: url("../images/book_black_background.jpeg");
  background-size: cover;
  background-attachment: fixed;
  background-position: center;

  .teams0 {
    padding: 64px 24px;
    display: flex;
    align-items: flex-end;
    .banner-anim {
      width: 100%;
      height: 100%;
    }
    .banner-anim-thumb {
      span {
        background: #e9e9e9;
        box-shadow: none;
        &.active {
          background: $primary-color;
        }
      }
    }
    & .queue-anim-leaving {
      position: relative !important;
    }

    &-banner-user-elem {
      height: 100%;
      color: #fff;
      position: relative;
      overflow: hidden;
    }
    &-image {
      width: 120px;
      height: 120px;
      border-radius: 100%;
      overflow: hidden;
      margin: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 24px;
      img {
        height: 100%;
      }
    }
    &-content {
      font-size: 12px;
      color: #919191;
      text-align: center;
      width: 80%;
      margin: 8px auto;
    }
    &-h1 {
      font-size: 24px;
      text-align: center;
      width: 80%;
      margin: 24px auto 0;
    }
  }
}

@media screen and (max-width: 767px) {
  .teams0-wrapper {
    min-height: 480px;

    .teams0 {
      display: block;
    }
  }
}
